import Vue from "vue";
import { post, put } from "../../utils/crud";
import { formatDateForRequest } from "../../utils/date-fromating";
const lodash = require("lodash");

const defaultState = () => {
  return {
    rewardingScheme: {
      name: "",
      storeId: null,
      valid: {
        start: null,
        end: null,
      },
      type: null,
      isTimeLimited: false,
      repetition: {
        isRepeating: false,
        perWeeks: 1,
        repeatingDays: [],
      },
      tokenAmount: 1,
      products: [],
      freeShipping: false,
    },

    requesting: false,
    edit: false,
  };
};

const state = defaultState();

const mutations = {
  SET_REQUESTING(state, status) {
    state.requesting = status;
  },
  SET_REWARDING_SCHEME(state, rewardingScheme) {
    state.rewardingScheme = lodash.cloneDeep(rewardingScheme);
    Vue.set(state.rewardingScheme, "storeId", rewardingScheme.store.id);

    state.edit = true;
  },
  UPDATE_REWARDING_SCHEME_FIELDS(state, { field, value }) {
    Vue.set(state.rewardingScheme, field, value);
  },
  UPDATE_REWARDING_SCHEME_REPETITION(state, { field, value }) {
    Vue.set(state.rewardingScheme.repetition, field, value);
  },
  UPDATE_REWARDING_SCHEME_PRODUCTS(state, products) {
    Vue.set(state.rewardingScheme, "products", products);
  },
  REMOVE_PRODUCT(state, index) {
    state.rewardingScheme.products.splice(index, 1);
  },
  RESET_STATE(state) {
    Object.assign(state, defaultState());
  },
};

const actions = {
  async createRewardingScheme({ state }) {
    let data = {};
    Object.assign(data, state.rewardingScheme);
    data.valid.start = formatDateForRequest(data.valid.start);
    data.valid.end = formatDateForRequest(data.valid.end);
    return await post("/rewards", data);
  },
  async saveRewardingScheme({ state }) {
    let data = {};
    Object.assign(data, state.rewardingScheme);
    data.valid.start = formatDateForRequest(data.valid.start);
    data.valid.end = formatDateForRequest(data.valid.end);
    return await put(`/rewards/${state.rewardingScheme.id}`, data);
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
