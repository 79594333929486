import axios from "axios";

const $axios = axios.create({ withCredentials: true });

export function userData() {
  return $axios.get(process.env.VUE_APP_API + "/auth/me");
}

export function setCsrfToken() {
  return $axios.get(process.env.VUE_APP_SANCTUM_CSRF);
}
