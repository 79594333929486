import Vue from "vue";
import { get } from "../../utils/crud";

const state = {
  totalRows: 0,
  perPage: 10,
  currentPage: 1,

  searchQuery: "",

  requesting: false,

  products: [],
};

const mutations = {
  SET_CURRENT_PAGE(state, currentPage) {
    state.currentPage = currentPage;
  },
  SET_TOTAL_ROWS(state, total) {
    state.totalRows = total;
  },
  SET_SEARCH_QUERY(state, searchValue) {
    state.searchQuery = searchValue;
  },
  SET_PRODUCTS(state, products) {
    Vue.set(state, "products", products);
  },
  SET_REQUESTING(state, requestingState) {
    state.requesting = requestingState;
  },
  UPDATE_PRODUCT_DATA(state, product) {
    const index = state.products.findIndex(({ id }) => id === product.id);
    state.products.splice(index, 1, product);
  },
  SET_PER_PAGE(state, perPage) {
    state.perPage = perPage;
  },
};

const actions = {
  async getProducts({ state, commit }) {
    try {
      commit("SET_REQUESTING", true);
      const res = await get(
        `/products?page=${state.currentPage}&perPage=${state.perPage}&search=${state.searchQuery}`
      );
      commit("SET_PRODUCTS", res.data.data);
      commit("SET_TOTAL_ROWS", res.data.meta.total);
    } catch (e) {
      console.error(e);
    } finally {
      commit("SET_REQUESTING", false);
    }
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
