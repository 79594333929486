import Vue from "vue";
import { get } from "../../utils/crud";
import { formatDateForRequest } from "../../utils/date-fromating";

const defaultState = () => {
  const currentDate = new Date();
  return {
    data: {
      registeredUsers: 0,
      claimedPoints: 0,
      usedPoints: 0,
      allCodes: 0,
      usedCodes: 0,
      claimedPointsSource: {
        promotions: 0,
        code: 0,
        purchase: 0,
        other: 0,
      },
      usersPerDay: {
        date: [],
        values: [],
      },
      claimedPerDay: {
        date: [],
        values: [],
      },
      usedPerDay: {
        date: [],
        values: [],
      },
      tokensPerProducts: [],
    },
    dateRange: {
      start: new Date(new Date().getFullYear(), 0, 1).toISOString(),
      end: currentDate.toISOString(),
    },
    requesting: true,
  };
};

const state = defaultState();

const mutations = {
  SET_REQUESTING(state, status) {
    state.requesting = status;
  },
  UPDATE_STATISTICS_FIELD(state, { field, value }) {
    Vue.set(state, field, value);
  },
};

const actions = {
  async getStatistics({ state, commit }) {
    try {
      commit("SET_REQUESTING", true);
      const res = await get(
        `/statistics?dateFrom=${formatDateForRequest(
          state.dateRange.start
        )}&dateTo=${formatDateForRequest(state.dateRange.end)}`
      );
      commit("UPDATE_STATISTICS_FIELD", { field: "data", value: res.data });
    } catch (e) {
      console.error(e);
    } finally {
      commit("SET_REQUESTING", false);
    }
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
