import Vue from "vue";
import { get } from "../../utils/crud";
import { formatDateForRequest } from "../../utils/date-fromating";

const state = {
  totalRows: 0,
  perPage: 10,
  currentPage: 1,

  searchQuery: "",
  dateRange: {
    start: null,
    end: null,
  },

  requesting: false,

  promotions: [],
  stores: [],
  promotionTypes: [],
};

const mutations = {
  SET_PROMOTIONS(state, promotions) {
    Vue.set(state, "promotions", promotions);
  },
  SET_PROMOTION_TYPES(state, promotionTypes) {
    Vue.set(state, "promotionTypes", promotionTypes);
  },
  SET_STORES(state, stores) {
    Vue.set(state, "stores", stores);
  },
  SET_CURRENT_PAGE(state, currentPage) {
    state.currentPage = currentPage;
  },
  SET_TOTAL_ROWS(state, total) {
    state.totalRows = total;
  },
  SET_SEARCH_QUERY(state, searchValue) {
    state.searchQuery = searchValue;
  },
  SET_DATE_RANGE(state, dateRange) {
    state.dateRange = dateRange;
  },
  SET_REQUESTING(state, requestingState) {
    state.requesting = requestingState;
  },
  ADD_PROMOTION(state, promotion) {
    state.promotions.push(promotion);
  },
  UPDATE_PROMOTION_DATA(state, promotion) {
    const index = state.promotions.findIndex(({ id }) => id === promotion.id);
    state.promotions.splice(index, 1, promotion);
  },
};

const actions = {
  async getPromotions({ state, commit }) {
    try {
      commit("SET_REQUESTING", true);
      const res = await get(
        `/promotions?page=${state.currentPage}&perPage=${
          state.perPage
        }&search=${state.searchQuery}&dateFrom=${formatDateForRequest(
          state.dateRange.start
        )}&dateTo=${formatDateForRequest(state.dateRange.end)}`
      );
      commit("SET_PROMOTIONS", res.data.data);
    } catch (e) {
      console.error(e);
    } finally {
      commit("SET_REQUESTING", false);
    }
  },
  async getPromotionsMeta({ commit }) {
    try {
      const res = await get("/promotions/meta");
      commit("SET_STORES", res.data.stores);
      commit("SET_PROMOTION_TYPES", res.data.promotionTypes);
    } catch (e) {
      console.error(e);
    }
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
