<template>
  <main id="app">
    <b-overlay
      :show="screenIsLoading"
      :fixed="true"
      opacity="1"
      spinner-variant="primary"
      spinner-type="grow"
      no-wrap
    >
    </b-overlay>
    <m-navigation v-if="user !== null" />
    <router-view />
  </main>
</template>

<script>
import { setCsrfToken } from "./utils/auth";
import MNavigation from "./components/navigation/MNavigation";
import { getLoyaltyGroups } from "./utils/meta-data";

export default {
  name: "MedisLoyaltyApp",
  components: { MNavigation },
  computed: {
    screenIsLoading() {
      return this.$store.state.screenIsLoading;
    },
    user() {
      return this.$store.state.user;
    },
  },
  async beforeCreate() {
    try {
      await setCsrfToken();
      const res = await getLoyaltyGroups();
      this.$store.commit("metaData/SET_ENGINES", res.data.data);
    } catch (e) {
      console.error(e);
    }
  },
};
</script>

<style lang="scss">
// color variables
@import "./assets/scss/variables";

// Bootstrap & Bootstrap Vue css
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-vue/src/index.scss";

// Font-awesome pro css
@import "~@fortawesome/fontawesome-pro/css/all.css";

// Custom style
@import "/assets/scss/login";
@import "/assets/scss/index";
</style>
