import axios from "axios";
import store from "../store/index";

const $axios = axios.create({ withCredentials: true });

$axios.interceptors.request.use(
  (config) => {
    if (store.state.user)
      config.headers["x-engine-id"] = store.state.metaData.engineId;

    config.baseURL = process.env.VUE_APP_API;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export function get(url, data) {
  return $axios.get(url, data);
}

export function put(url, data) {
  return $axios.put(url, data);
}

export function post(url, data) {
  return $axios.post(url, data);
}

export function patch(url, data) {
  return $axios.patch(url, data);
}
