import Vue from "vue";
import { get } from "../../utils/crud";
import { formatDateForRequest } from "../../utils/date-fromating";

const state = {
  totalRows: 0,
  perPage: 10,
  currentPage: 1,

  searchQuery: "",
  dateRange: {
    start: null,
    end: null,
  },

  requesting: false,

  rewardingSchemes: [],
  stores: [],
  rewardingSchemeTypes: [],
};

const mutations = {
  SET_REWARDING_SCHEMES(state, rewardingSchemes) {
    Vue.set(state, "rewardingSchemes", rewardingSchemes);
  },
  SET_REWARDING_SCHEME_TYPES(state, rewardingSchemeTypes) {
    Vue.set(state, "rewardingSchemeTypes", rewardingSchemeTypes);
  },
  SET_STORES(state, stores) {
    Vue.set(state, "stores", stores);
  },
  SET_CURRENT_PAGE(state, currentPage) {
    state.currentPage = currentPage;
  },
  SET_TOTAL_ROWS(state, total) {
    state.totalRows = total;
  },
  SET_SEARCH_QUERY(state, searchValue) {
    state.searchQuery = searchValue;
  },
  SET_DATE_RANGE(state, dateRange) {
    state.dateRange = dateRange;
  },
  SET_REQUESTING(state, requestingState) {
    state.requesting = requestingState;
  },
  ADD_REWARDING_SCHEME(state, rewardingScheme) {
    state.rewardingSchemes.push(rewardingScheme);
  },
  UPDATE_REWARDING_SCHEME_DATA(state, rewardingScheme) {
    const index = state.rewardingSchemes.findIndex(
      ({ id }) => id === rewardingScheme.id
    );
    state.rewardingSchemes.splice(index, 1, rewardingScheme);
  },
};

const actions = {
  async getRewardingSchemes({ state, commit }) {
    try {
      commit("SET_REQUESTING", true);
      const res = await get(
        `/rewards?page=${state.currentPage}&perPage=${state.perPage}&search=${
          state.searchQuery
        }&dateFrom=${formatDateForRequest(
          state.dateRange.start
        )}&dateTo=${formatDateForRequest(state.dateRange.end)}`
      );
      commit("SET_REWARDING_SCHEMES", res.data.data);
    } catch (e) {
      console.error(e);
    } finally {
      commit("SET_REQUESTING", false);
    }
  },
  async getRewardingSchemesMeta({ commit }) {
    try {
      const res = await get("/rewards/meta");
      commit("SET_STORES", res.data.stores);
      commit("SET_REWARDING_SCHEME_TYPES", res.data.rewardTypes);
    } catch (e) {
      console.error(e);
    }
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
