import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import metaData from "./metaData";
import productsTable from "./products/productsTable";
import product from "./products/product";

import client from "./clients/client";
import clientsTable from "./clients/clientsTable";
import clientInfo from "./clients/clientInfo";

import promotionsTable from "./promotions/promotionsTable";
import promotion from "./promotions/promotion";

import rewardingSchemeTable from "./rewarding-scheme/rewardingSchemeTable";
import rewardingScheme from "./rewarding-scheme/rewardingScheme";

import codeGeneratorTable from "./code-generator/codeGeneratorTable";
import codeGenerator from "./code-generator/codeGenerator";

import statistics from "./statistics/statistics";

export default new Vuex.Store({
  state: {
    user: null,
    screenIsLoading: false,
  },
  mutations: {
    SET_USER_DATA(state, userData) {
      state.user = userData;
    },
    CHANGE_SCREEN_LOADING_STATUS(state, status) {
      state.screenIsLoading = status;
    },
  },
  actions: {},
  modules: {
    metaData,
    productsTable,
    product,

    clientsTable,
    client,
    clientInfo,

    promotionsTable,
    promotion,

    rewardingSchemeTable,
    rewardingScheme,

    codeGeneratorTable,
    codeGenerator,

    statistics,
  },
});
