import Vue from "vue";
import { patch, post, put } from "../../utils/crud";
const lodash = require("lodash");

const defaultState = () => {
  return {
    batch: {
      name: "",
      product: {},
      codePattern: null,
      sentForPrinting: false,
      quantity: 1,
      codes: [],
      numberOfNewLabels: 0,
    },
    selectedCode: {
      code: null,
    },
    deactivationData: {
      cause: "",
      comment: "",
    },
    assignData: {
      cause: "",
      comment: "",
      customer: null,
    },

    requesting: false,
    edit: false,
  };
};

const state = defaultState();

const mutations = {
  SET_REQUESTING(state, status) {
    state.requesting = status;
  },
  SET_BATCH(state, batch) {
    state.batch = lodash.cloneDeep(batch);
    state.batch.numberOfNewLabels = 0;
    state.edit = true;
  },
  SET_SELECTED_CODE(state, code) {
    state.selectedCode = lodash.cloneDeep(code);
  },
  UPDATE_BATCH_FIELDS(state, { field, value }) {
    Vue.set(state.batch, field, value);
  },
  UPDATE_DEACTIVATION_FIELDS(state, { field, value }) {
    Vue.set(state.deactivationData, field, value);
  },
  UPDATE_ASSIGN_FIELDS(state, { field, value }) {
    Vue.set(state.assignData, field, value);
  },
  UPDATE_REWARDING_SCHEME_REPETITION(state, { field, value }) {
    Vue.set(state.rewardingScheme.repetition, field, value);
  },
  UPDATE_REWARDING_SCHEME_PRODUCTS(state, products) {
    Vue.set(state.rewardingScheme, "products", products);
  },
  REMOVE_PRODUCT(state, index) {
    state.rewardingScheme.products.splice(index, 1);
  },
  RESET_STATE(state) {
    Object.assign(state, defaultState());
  },
  RESET_ASSIGN_STATE(state) {
    const data = {
      cause: "",
      comment: "",
      customer: null,
    };
    Object.assign(state.assignData, data);
  },
  RESET_DEACTIVATION_STATE(state) {
    const data = {
      cause: "",
      comment: "",
    };
    Object.assign(state.deactivationData, data);
  },

  UPDATE_CODE(state, code) {
    const index = state.batch.codes.findIndex((el) => el.id === code.id);
    Vue.set(state.batch.codes, index, code);
  },
};

const actions = {
  async createBatch({ state }) {
    let data = {};
    Object.assign(data, state.batch);
    return await post("/codes", data);
  },
  async deactivateCode({ state }) {
    let data = {};
    Object.assign(data, state.deactivationData);
    return await patch(`/codes/deactivate/${state.selectedCode.id}`, data);
  },
  async assignCode({ state }) {
    let data = {
      customerId: state.assignData.customer.id,
      comment: state.assignData.comment,
      cause: state.assignData.cause,
    };
    return await post(`/codes/assign/${state.selectedCode.id}`, data);
  },
  async updateBatch({ state }) {
    let data = {
      name: state.batch.name,
      generateNewCodes: state.batch.numberOfNewLabels,
      sentForPrinting: state.batch.sentForPrinting,
    };
    return await put(`/codes/${state.batch.id}`, data);
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
