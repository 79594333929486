<template>
  <div class="d-flex align-items-center justify-content-between m-nav__wrapper">
    <div class="d-flex align-items-center">
      <img
        :src="require('../../assets/images/logo/logo_Medis-color.png')"
        class="m-nav__logo"
      />
      <nav class="m-nav__list">
        <router-link
          :to="{ name: 'Statistics' }"
          class="m-nav__item"
          :class="{ active: $route.name === 'Statistics' }"
          >Statistics</router-link
        >
        <router-link
          :to="{ name: 'Reports' }"
          class="m-nav__item"
          :class="{ active: $route.name === 'Reports' }"
          >Reports</router-link
        >
        <router-link
          :to="{ name: 'Products' }"
          class="m-nav__item"
          :class="{ active: $route.name === 'Products' }"
          >Products</router-link
        >
        <router-link
          :to="{ name: 'Clients' }"
          class="m-nav__item"
          :class="{ active: $route.name === 'Clients' }"
          >Clients</router-link
        >
        <router-link
          :to="{ name: 'RewardingScheme' }"
          class="m-nav__item"
          :class="{ active: $route.name === 'RewardingScheme' }"
          >Rewarding schemes</router-link
        >
        <router-link
          :to="{ name: 'Promotions' }"
          class="m-nav__item"
          :class="{ active: $route.name === 'Promotions' }"
          >Promotions</router-link
        >
        <router-link
          :to="{ name: 'CodeGenerator' }"
          class="m-nav__item"
          :class="{ active: $route.name === 'CodeGenerator' }"
          >Code generator</router-link
        >
      </nav>
    </div>
    <div class="d-flex align-items-center justify-content-end">
      <span class="m-nav__user mr-3"
        ><i class="fad fa-user"></i
        >{{ user.firstName + " " + user.lastName[0] }}.</span
      >
      <div class="d-flex align-items-center m-nav__group-wrapper">
        <i
          class="fas fa-info-circle"
          v-b-tooltip="'Choose your loyalty group (MedisPlus or Donna)'"
        ></i>
        <b-select
          v-model="selectedGroup"
          class="py-1 m-nav__group-select"
          style="height: unset; min-width: 130px"
        >
          <b-select-option
            v-for="option in groups"
            :key="option.id"
            :value="option.id"
            >{{ option.name }}</b-select-option
          >
        </b-select>
      </div>
      <b-button variant="outline-primary" size="sm" @click="logout"
        >Logout</b-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "MNavigation",
  computed: {
    user() {
      return this.$store.state.user;
    },
    groups() {
      return this.$store.state.metaData.engines;
    },
    selectedGroup: {
      get() {
        return this.$store.state.metaData.engineId;
      },
      set(value) {
        this.$store.commit("metaData/SET_ENGINE_ID", value);
      },
    },
  },
  methods: {
    async logout() {
      await this.$axios.get("/auth/logout");
      this.$router.go(0);
    },
  },
};
</script>

<style scoped></style>
