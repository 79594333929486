import Vue from "vue";
import { put } from "../../utils/crud";

const state = {
  product: {
    name: "",
    sku: "",
    tokens: 0,
  },
};

const mutations = {
  SET_PRODUCT_DATA(state, product) {
    Vue.set(state, "product", product);
  },
};

const actions = {
  changeProductTokenValue({ state }, { newTokenValue, comment }) {
    return put("/product-tokens", {
      productId: state.product.id,
      value: newTokenValue,
      comment,
    });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
