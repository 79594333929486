import Vue from "vue";
import { put } from "../../utils/crud";

const state = {
  client: {},
};

const mutations = {
  SET_CLIENT_DATA(state, client) {
    Vue.set(state, "client", client);
  },
};

const actions = {
  async updateClientTokens({ state }, data) {
    return await put(`/clients/${state.client.id}/update-tokens`, data);
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
