const state = {
  engines: [],
  engineId: 1,
};

const mutations = {
  SET_ENGINES(state, engines) {
    state.engines = engines;
  },
  SET_ENGINE_ID(state, id) {
    state.engineId = id;
  },
};

const actions = {};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
