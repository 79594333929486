import Vue from "vue";
import VueRouter from "vue-router";

import { userData } from "../utils/auth";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login"),
  },
  {
    path: "/reports",
    name: "Reports",
    component: () => import("../views/MReports"),
  },
  {
    path: "/products",
    name: "Products",
    component: () => import("../views/MProducts"),
  },
  {
    path: "/clients",
    name: "Clients",
    component: () => import("../views/MClients"),
  },
  {
    path: "/promotions",
    name: "Promotions",
    component: () => import("../views/MPromotions"),
  },
  {
    path: "/rewards",
    name: "RewardingScheme",
    component: () => import("../views/MRewardingScheme"),
  },
  {
    path: "/code-generator",
    name: "CodeGenerator",
    component: () => import("../views/MCodeGenerator"),
  },
  {
    path: "/statistics",
    name: "Statistics",
    component: () => import("../views/MStatistics"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const store = router.app.$root.$store;
  try {
    store.commit("CHANGE_SCREEN_LOADING_STATUS", true);

    if (to.name !== "Login" && store.state.user === null) {
      const res = await userData();
      router.app.$store.commit("SET_USER_DATA", res.data);
    }

    if (to.name === "Login") {
      if (store.state.user === null) next();
      else next({ name: "ProductsTable" });
    }

    next();
  } catch (e) {
    if (e.response.status === 401) {
      next({ name: "Login" });
    } else {
      console.error(e);
    }
  } finally {
    store.commit("CHANGE_SCREEN_LOADING_STATUS", false);
  }
});

export default router;
