import Vue from "vue";
import { post, put } from "../../utils/crud";
import { formatDateForRequest } from "../../utils/date-fromating";
const lodash = require("lodash");

const defaultState = () => {
  return {
    promotion: {
      name: "",
      storeId: null,
      valid: {
        start: null,
        end: null,
      },
      type: null,
      repetition: {
        isRepeating: false,
        perWeeks: 0,
        repeatingDays: [],
      },
      tokenAmount: 0,
      products: [],
    },

    requesting: false,
    edit: false,
  };
};

const state = defaultState();

const mutations = {
  SET_REQUESTING(state, status) {
    state.requesting = status;
  },
  SET_PROMOTION(state, promotion) {
    state.promotion = lodash.cloneDeep(promotion);
    Vue.set(state.promotion, "storeId", promotion.store.id);

    state.edit = true;
  },
  UPDATE_PROMOTION_FIELDS(state, { field, value }) {
    Vue.set(state.promotion, field, value);
  },
  UPDATE_PROMOTION_REPETITION(state, { field, value }) {
    Vue.set(state.promotion.repetition, field, value);
  },
  UPDATE_PROMOTION_PRODUCTS(state, products) {
    Vue.set(state.promotion, "products", products);
  },
  REMOVE_PRODUCT(state, index) {
    state.promotion.products.splice(index, 1);
  },
  RESET_STATE(state) {
    Object.assign(state, defaultState());
  },
};

const actions = {
  async createPromotion({ state }) {
    let data = {};
    Object.assign(data, state.promotion);
    data.valid.start = formatDateForRequest(data.valid.start);
    data.valid.end = formatDateForRequest(data.valid.end);
    return await post("/promotions", data);
  },
  async savePromotion({ state }) {
    let data = {};
    Object.assign(data, state.promotion);
    data.valid.start = formatDateForRequest(data.valid.start);
    data.valid.end = formatDateForRequest(data.valid.end);
    return await put(`/promotions/${state.promotion.id}`, data);
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
