import Vue from "vue";
import { get } from "../../utils/crud";
import { formatDateForRequest } from "../../utils/date-fromating";

const state = {
  totalRows: 0,
  perPage: 10,
  currentPage: 1,

  searchQuery: "",
  dateRange: {
    start: null,
    end: null,
  },

  requesting: false,

  clients: [],
  causeCategories: [],
};

const mutations = {
  SET_CURRENT_PAGE(state, currentPage) {
    state.currentPage = currentPage;
  },
  SET_TOTAL_ROWS(state, total) {
    state.totalRows = total;
  },
  SET_SEARCH_QUERY(state, searchValue) {
    state.searchQuery = searchValue;
  },
  SET_DATE_RANGE(state, dateRange) {
    state.dateRange = dateRange;
  },
  SET_CLIENTS(state, clients) {
    Vue.set(state, "clients", clients);
  },
  SET_CAUSE_CATEGORIES(state, categories) {
    Vue.set(state, "causeCategories", categories);
  },
  SET_REQUESTING(state, requestingState) {
    state.requesting = requestingState;
  },
  UPDATE_CLIENT_DATA(state, client) {
    const index = state.clients.findIndex(({ id }) => id === client.id);
    state.clients.splice(index, 1, client);
  },
};

const actions = {
  async getClients({ state, commit }) {
    try {
      commit("SET_REQUESTING", true);
      const res = await get(
        `/clients?page=${state.currentPage}&perPage=10&search=${
          state.searchQuery
        }&dateFrom=${formatDateForRequest(
          state.dateRange.start
        )}&dateTo=${formatDateForRequest(state.dateRange.end)}`
      );
      commit("SET_CLIENTS", res.data.data);
      commit("SET_TOTAL_ROWS", res.data.meta.total);
    } catch (e) {
      console.error(e);
    } finally {
      commit("SET_REQUESTING", false);
    }
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
