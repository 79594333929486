import Vue from "vue";
import { get } from "../../utils/crud";
import { formatDateForRequest } from "../../utils/date-fromating";

const setDefaultState = () => {
  return {
    tableComponent: "orders-table",
    orders: [],
    selectedOrder: null,
    tokenLogs: [],

    totalRows: 0,
    perPage: 10,
    currentPage: 1,

    searchQuery: "",
    dateRange: {
      start: null,
      end: null,
    },

    requesting: false,
  };
};

const state = setDefaultState();

const mutations = {
  CHANGE_TABLE_COMPONENT(state, component) {
    state.tableComponent = component;
  },
  SET_TABLE_DATA(state, { tableName, data }) {
    Vue.set(state, tableName, data);
  },
  SET_CURRENT_PAGE(state, currentPage) {
    state.currentPage = currentPage;
  },
  SET_TOTAL_ROWS(state, total) {
    state.totalRows = total;
  },
  SET_SEARCH_QUERY(state, searchQuery) {
    state.searchQuery = searchQuery;
  },
  SET_DATE_RANGE(state, dateRange) {
    state.dateRange = dateRange;
  },
  SET_REQUESTING(state, status) {
    state.requesting = status;
  },
  SET_SELECTED_ORDER(state, data) {
    Vue.set(state, "selectedOrder", data);
  },
  RESET_STATE(state) {
    Object.assign(state, setDefaultState());
  },
};

const actions = {
  async getClientOrders({ state, rootState, commit }) {
    try {
      commit("SET_REQUESTING", true);
      const res = await get(
        `/clients/${rootState.client.client.id}/orders?page=${
          state.currentPage
        }&perPage=${state.perPage}&search=${
          state.searchQuery
        }&dateFrom=${formatDateForRequest(
          state.dateRange.start
        )}&dateTo=${formatDateForRequest(state.dateRange.end)}`
      );
      commit("SET_TABLE_DATA", {
        tableName: "orders",
        data: res.data.data,
      });
      commit("SET_TOTAL_ROWS", res.data.meta.total);
    } catch (e) {
      console.error(e);
    } finally {
      commit("SET_REQUESTING", false);
    }
  },
  async getClientTokenLogs({ state, rootState, commit }) {
    try {
      commit("SET_REQUESTING", true);
      const res = await get(
        `/clients/${rootState.client.client.id}/balance?page=${
          state.currentPage
        }&perPage=${state.perPage}&search=${
          state.searchQuery
        }&dateFrom=${formatDateForRequest(
          state.dateRange.start
        )}&dateTo=${formatDateForRequest(state.dateRange.end)}`
      );
      commit("SET_TABLE_DATA", {
        tableName: "tokenLogs",
        data: res.data.data,
      });
      commit("SET_TOTAL_ROWS", res.data.meta.total);
    } catch (e) {
      console.error(e);
    } finally {
      commit("SET_REQUESTING", false);
    }
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
