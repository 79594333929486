import store from "./store";
import router from "./router";
import Vue from "vue";
import App from "./App.vue";

/* Plugin imports */

//BootstrapVue - used for component source, layout etc.
import { BootstrapVue } from "bootstrap-vue";
//Import axios
import axios from "axios";

// VeeValidate - used for validating inputs
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize,
  setInteractionMode,
} from "vee-validate";
import { required, email, min_value } from "vee-validate/dist/rules";
import en from "vee-validate/dist/locale/en.json";

//VCalendar select - used for date-pick and time-pick components
import VCalendar from "v-calendar";

// Multiselect - for dynamic select dropdown component
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

//Lodash - working with arrays, objects
import _ from "lodash";

// ApexCharts - plotting library
import VueApexCharts from "vue-apexcharts";

/* Plugins uses */

//BootstrapVue
Vue.use(BootstrapVue);

//VeeValidate
Vue.component("validation-observer", ValidationObserver);
Vue.component("validation-provider", ValidationProvider);
extend("required", required);
extend("email", email);
extend("min_value", min_value);
localize("en", en);
setInteractionMode("eager");

extend("date-validation", (value) => {
  if (value.start !== null && value.end !== null) return true;

  return "The {_field_} field is required";
});

//VCalendar
Vue.use(VCalendar);

//Multiselect
Vue.use(Multiselect);
Vue.component("multiselect", Multiselect);

//Axios
Vue.prototype.$axios = axios.create({ withCredentials: true });
Vue.prototype.$axios.interceptors.request.use(
  (config) => {
    if (store.state.user)
      config.headers["x-engine-id"] = store.state.metaData.engineId;

    config.baseURL = process.env.VUE_APP_API;
    return config;
  },
  (error) => {
    if (error.response.status === 401) {
      store.commit("SET_USER_DATA", null);
      router.push({ name: "Login" });
    }
    return Promise.reject(error);
  }
);

Vue.config.productionTip = false;

Vue.filter("date", function (value) {
  value = value.split("/").reverse().join("/");
  let date = new Date(value);
  let year = new Intl.DateTimeFormat("sl", {
    year: "numeric",
  }).format(date);
  let month = new Intl.DateTimeFormat("sl", {
    month: "2-digit",
  }).format(date);
  let day = new Intl.DateTimeFormat("sl", { day: "2-digit" }).format(date);
  return `${day}${month}.${year}`;
});

Vue.mixin({
  methods: {
    downloadFile(file, fileName, fileType = ".csv") {
      const url = window.URL.createObjectURL(new Blob([file]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName + fileType); //or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    },
  },
});

Vue.prototype._ = _;

Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
