import Vue from "vue";
import { get } from "../../utils/crud";
import { formatDateForRequest } from "../../utils/date-fromating";

const state = {
  totalRows: 0,
  perPage: 10,
  currentPage: 1,

  searchQuery: "",
  dateRange: {
    start: null,
    end: null,
  },

  requesting: false,

  batches: [],
  stores: [],
  products: [],
  codePatterns: [],
  rewardingSchemeTypes: [],
  codes: [],
  codesCurrentPage: 1,
  codesTotalRows: 0,
  codesPerPage: 150,
  codesSearchQuery: "",
};

const mutations = {
  SET_BATCHES(state, batches) {
    Vue.set(state, "batches", batches);
  },
  SET_REWARDING_SCHEME_TYPES(state, rewardingSchemeTypes) {
    Vue.set(state, "rewardingSchemeTypes", rewardingSchemeTypes);
  },
  SET_PRODUCTS(state, products) {
    Vue.set(state, "products", products);
  },
  SET_CODE_PATTERNS(state, codePatterns) {
    Vue.set(state, "codePatterns", codePatterns);
  },
  SET_CURRENT_PAGE(state, currentPage) {
    state.currentPage = currentPage;
  },
  SET_CODES_CURRENT_PAGE(state, currentPage) {
    state.codesCurrentPage = currentPage;
  },
  SET_TOTAL_ROWS(state, total) {
    state.totalRows = total;
  },
  SET_CODES_TOTAL_ROWS(state, total) {
    state.codesTotalRows = total;
  },
  SET_SEARCH_QUERY(state, searchValue) {
    state.searchQuery = searchValue;
  },
  SET_CODES_SEARCH_QUERY(state, searchValue) {
    state.codesSearchQuery = searchValue;
  },
  SET_DATE_RANGE(state, dateRange) {
    state.dateRange = dateRange;
  },
  SET_REQUESTING(state, requestingState) {
    state.requesting = requestingState;
  },
  ADD_BATCH(state, batch) {
    state.batches.push(batch);
  },
  UPDATE_BATCH(state, batch) {
    const index = state.batches.findIndex(({ id }) => id === batch.id);
    Vue.set(state.batches, index, batch);
  },
  SET_BATCH_SELECTED(state, { batch, isSelected }) {
    const index = state.batches.findIndex(({ id }) => id === batch.id);
    Vue.set(state.batches[index], "isSelected", isSelected);
  },
  SET_CODES(state, codes) {
    Vue.set(state, "codes", codes);
  },
  UPDATE_CODE(state, code) {
    const index = state.codes.findIndex((el) => el.id === code.id);
    Vue.set(state.codes, index, code);
  },
};

const actions = {
  async getCodeBatches({ state, commit }) {
    try {
      commit("SET_REQUESTING", true);
      const res = await get(
        `/codes?page=${state.currentPage}&perPage=${state.perPage}&search=${
          state.searchQuery
        }&dateFrom=${formatDateForRequest(
          state.dateRange.start
        )}&dateTo=${formatDateForRequest(state.dateRange.end)}`
      );
      commit("SET_BATCHES", res.data.data);
      commit("SET_TOTAL_ROWS", res.data.meta.total);
    } catch (e) {
      console.error(e);
    } finally {
      commit("SET_REQUESTING", false);
    }
  },
  async getCodeGeneratorsMeta({ commit }) {
    try {
      commit("SET_REQUESTING", true);
      const res = await get("/codes/meta");
      commit("SET_PRODUCTS", res.data.products);
      commit("SET_CODE_PATTERNS", res.data.codePatterns);
    } catch (e) {
      console.error(e);
    } finally {
      commit("SET_REQUESTING", false);
    }
  },
  async getSelectedBatchCodes({ commit }, batchId) {
    try {
      const res = await get(
        "/codes/batch-codes/" +
          batchId +
          `?page=${state.codesCurrentPage}&search=${state.codesSearchQuery}`
      );
      commit("SET_CODES", res.data.data);
      commit("SET_CODES_TOTAL_ROWS", res.data.meta.total);
    } catch (e) {
      console.error(e);
    }
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
